@font-face {
  font-family: Riposte;
  src: url(./assets/fonts/Riposte-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: Riposte;
  src: url(./assets/fonts/Riposte-Bold.otf);
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: Louize;
  src: url(./assets/fonts/Louize-Regular-205TF.woff);
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Louize", "Riposte",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

code,
kbd,
samp,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:focus {
  outline: none;
}
